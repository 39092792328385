import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ProductModel} from '../../core/model';
import {Router} from '@angular/router';

export const LABELS = {
  "type": "Type",
  "soundSuppression": "Net sound suppression",
  "overalLength": "Overal length",
  "addedLength": "Added length to weapon",
  "weight": "Weight",
  "diameter": "Diameter"
}

@Component({
  selector: 'product-table-component',
  templateUrl: './product-table.component.html',
  styleUrls: ['./product-table.component.scss']
})
export class ProductTableComponent implements OnInit, OnChanges {

  @Input()
  product: ProductModel;

  @Input()
  displayedColumns: string[];

  dataSource: any;

  system = 'metric';

  labels = LABELS;

  constructor(private router: Router) {
    this.router.events.subscribe((val) => this.load())
  }

  setTo(system: string) {
    this.system = system;
    this.dataSource = this.product.table.rows.filter(t => t.system === system)
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.dataSource = this.product.table.rows.filter(t => t.system === this.system);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.load();
  }
}
