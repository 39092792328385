import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CatalogueComponent} from "./catalogue.component";
import {MaterialModule} from '../material.module';
import {RouterModule} from '@angular/router';
import {CATALOGUE_ROUTES} from './catalogue.route';
import {CommonModule} from '@angular/common';
import {CategoryListComponent} from './category-list';
import {ProductListComponent} from './product-list';
import {ProductComponent} from './product';
import {CarouselComponent} from './carousel-component';
import {ProductTableComponent} from './product-table';
import {FormsModule} from '@angular/forms';
import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';
import {FlexModule} from '@angular/flex-layout';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  imports: [RouterModule.forChild(CATALOGUE_ROUTES), MaterialModule, CommonModule, FormsModule, SwiperModule, FlexModule],
  declarations: [CatalogueComponent, CategoryListComponent, ProductListComponent, ProductComponent, CarouselComponent, ProductTableComponent],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CatalogueModule {
}
