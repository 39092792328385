import {Component, Input} from '@angular/core';
import {GalleryModel} from '../../core/model';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';

@Component({
  selector: 'carousel-component',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {
  @Input()
  gallery: GalleryModel;

  config: SwiperConfigInterface = {
    a11y: true,
    autoplay: true,
    direction: 'horizontal',
    loop: true,
    autoHeight: true,
    slidesPerView: 1,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: true,
    zoom: true,
    watchOverflow: false,
    slidesOffsetBefore: 0,
    spaceBetween: 0

  };
}
