import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';


@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        // {
        //   path: '',
        //   loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
        // },
        {
          path: '',
          loadChildren: () => import('./catalogue/catalogue.module').then(m => m.CatalogueModule)
        },
      ]
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
