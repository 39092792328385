import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {HomeComponent} from "./home.component";
import {HOME_ROUTE} from './home.route';
import {RouterModule} from '@angular/router';
import {MaterialModule} from '../material.module';

@NgModule({
  imports: [RouterModule.forChild([HOME_ROUTE]), MaterialModule],
  declarations: [HomeComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HomeModule {
}
