import {Component, OnChanges, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductModel} from '../../core/model/product.model';
import {CategoryModel} from '../../core/model/category.model';
import {CatalogueService} from '../../core/service/catalogue.service';
import {EventManager} from '@angular/platform-browser';


@Component({
  selector: 'product-list-component',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, OnChanges {

  products: ProductModel[]
  category: CategoryModel;
  firstLoad = true;
  index: 0;

  constructor(private route: ActivatedRoute, private catalogueService: CatalogueService, private router: Router, private eventManager: EventManager) {
    this.router.events.subscribe((val) => this.load())
  }

  ngOnInit(): void {
    this.load();
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {

  }

  load() {
    this.category = this.catalogueService.getCategory(this.route.snapshot.params['category']);
    this.products = this.catalogueService.getProducts(this.category);

  }

  selectProduct(tab: any): void {
    if (this.products.length > 0) {
      this.router.navigate(['.', 'catalogue', 'category', this.category.path, 'product', this.products[tab.index].path], {replaceUrl: true}).then(r => {
      })
    }
  }


}
