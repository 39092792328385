import {Routes} from '@angular/router';
import {CatalogueComponent} from './catalogue.component';
import {ProductListComponent} from './product-list';
import {ProductComponent} from './product';

export const CATALOGUE_ROUTES: Routes = [
  {
    path: '',
    component: CatalogueComponent
  },
  {
    path: 'catalogue',
    component: CatalogueComponent,
    children: [{
      path: 'category/:category',
      component: ProductListComponent,
      children: [{
        path: 'product/:product',
        component: ProductComponent,
      }]
    }]

  }];
