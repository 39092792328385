import {Injectable} from '@angular/core';
import {CategoryModel, ClassifierElement, ProductModel, SiteModel} from '../model';
// @ts-ignore
import *  as  data from '../../catalogue-data/en.json';

@Injectable({providedIn: 'root'})
export class CatalogueService {

  public getSite(): SiteModel {
    return (data as any).default;
  }

  public getCategory(categoryPath: string): CategoryModel {
    return this.getSite().catalogue.categories.filter(category => category.path === categoryPath).pop();
  }

  public getCategoryByType(type: ClassifierElement): CategoryModel {
    return this.getSite().catalogue.categories.filter(category => category.type.code === type.code).pop();
  }

  public getProducts(category: CategoryModel): ProductModel[] {
    return this.getSite().catalogue.products.filter(product => product.type.code === category.type.code);
  }

  public getProduct(productPath: string): ProductModel {
    return this.getSite().catalogue.products.filter(product => product.path === productPath).pop();
  }
}

