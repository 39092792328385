import {Component, Input} from '@angular/core';
import {CategoryModel} from '../../core/model';
import {Router} from '@angular/router';
import {CatalogueService} from '../../core/service/catalogue.service';


@Component({
  selector: 'category-list-component',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent {
  @Input()
  categories: CategoryModel[];

  category: CategoryModel;

  constructor(private router: Router, private catalogueService: CatalogueService) {
  }

  selectCategory(category: CategoryModel) {
    this.router.navigate(['.', 'catalogue', 'category', category.path], {replaceUrl: true}).then(() => {
      this.selectProduct(category, {"index": 0})
      this.category = category;
    });
  }

  selectProduct(category: CategoryModel, tab: any): void {
    const products = this.catalogueService.getProducts(category);
    if (products.length > 0) {
      this.router.navigate(['.', 'catalogue', 'category', category.path, 'product', products[tab.index].path]).then(r => {
      })
    }
  }
}
