import {Component, OnInit} from '@angular/core';
import {CatalogueService} from '../core/service/catalogue.service';
import {SiteModel} from '../core/model/site.model';
import {CatalogueModel} from '../core/model/catalogue.model';


@Component({
  selector: 'catalogue-component',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.scss']
})
export class CatalogueComponent implements OnInit {
  site: SiteModel;
  catalogue:CatalogueModel;

  constructor(private catalogueService: CatalogueService) {
  }

  ngOnInit(): void {
    this.site = this.catalogueService.getSite();
    this.catalogue=this.site.catalogue;
  }
}
