import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CatalogueService} from '../../core/service/catalogue.service';
import {CategoryModel, ProductModel} from '../../core/model';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';


@Component({
  selector: 'product-component',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  product: ProductModel;
  category: CategoryModel;

  config: SwiperConfigInterface;

  constructor(private route: ActivatedRoute, private router: Router, private catalogueService: CatalogueService) {
    this.router.events.subscribe((val) => this.load())
  }

  ngOnInit(): void {
    this.load();
  }

  load() {

    if (this.route.snapshot.params['product']) {
      this.product = this.catalogueService.getProduct(this.route.snapshot.params['product']);
      this.category = this.catalogueService.getCategoryByType(this.product.type);
    }

  }

  getManual(manual): string {
    return window.location.origin + '/assets/manuals/' + manual;
  }
}
